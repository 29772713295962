var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"87a87abc0ff2d16c4193be3519eab8704198364b"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { RewriteFrames } from '@sentry/integrations'
import * as Sentry from '@sentry/nextjs'
import getConfig from 'next/config'

const config = getConfig()
const distDir = `${config.serverRuntimeConfig.rootDir}/.next`
const environment = process.env.NEXT_PUBLIC_APP_ENV

Sentry.init({
	dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
	enabled: process.env.NODE_ENV === 'production',
	environment,

	/* ---- Sentry Replay ---- */
	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,
	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,
	/* ----------------------- */

	integrations: [
		new Sentry.Replay({
			maskAllInputs: false,
			maskAllText: false,
			blockAllMedia: false,
		}),
		new RewriteFrames({
			iteratee: (frame) => {
				frame.filename = frame?.filename?.replace(distDir, 'app:///_next')
				return frame
			},
		}),
	],
	ignoreErrors: [
		'ResizeObserver loop limit exceeded',
		'Abort route change. Please ignore this error.Abort route change',
	],
})
