export const en = {
	'widget_editor.header.button.go_back': 'Back to list',

	'widget_editor.settings.title': 'Widget Settings',
	'widget_editor.tabs.placement': 'Where should it be placed ?',

	'widget_editor.name.placeholder': 'Widget Name',
	'widget_editor.status.title': 'Status',
	'widget_editor.status.active': 'Widget is live',
	'widget_editor.status.disabled': 'Widget is offline',
	'widget_editor.dynamic_alias.placeholder': 'Dynamic Alias',
	'widget_editor.dynamic_alias.tooltip': `Assign a unique, memorable name to your web story for easy embedding and sharing, without using the ID.<br></br>A simpler way to reference your banners!`,

	'widget_editor.appearance.title.story_text': 'Story Bubble Text',
	'widget_editor.appearance.title.story_border': 'Story Bubble Border',
	'widget_editor.appearance.title.story_bubble_size': 'Story Bubble Size',
	'widget_editor.appearance.title.story_card_size': 'Story Card Size',
	'widget_editor.appearance.title.story_card_border_radius': 'Story Card Border Radius',
	'widget_editor.appearance.title.story_caption_fontSize': 'Caption Font Size',
	'widget_editor.appearance.title.marginTop': 'Padding Top',

	'widget_editor.appearance.banner.color': 'Background Color',
	'widget_editor.appearance.banner.styles': 'Banner Styles',
	'widget_editor.appearance.banner.has_play_icon': 'Show play icon',
	'widget_editor.appearance.banner.has_play_icon.tooltip':
		'Show a "play" icon in each circle to increase the click rate.',
	'widget_editor.appearance.banner.has_attribution': 'Show name and logo on the top left side',
	'widget_editor.appearance.banner.has_attribution.tooltip':
		'For clarity, display the name and logo of a story when it is open.',
	'widget_editor.appearance.banner.has_flex_wrap': 'Multiline',
	'widget_editor.appearance.banner.has_flex_wrap.tooltip':
		'Instead of having to scroll, you can display all the stories at once in multiple lines',
	'widget_editor.appearance.story_text.color': 'Color',
	'widget_editor.appearance.story_text.fontFamily': 'Font Family',
	'widget_editor.appearance.story_border.start_color': 'Gradient start color',
	'widget_editor.appearance.story_border.end_color': 'Gradient end color',
	'widget_editor.appearance.story_border.radius': 'Border Radius',
	'widget_editor.appearance.card_shadow': 'Card Shadow',
	'widget_editor.appearance.card_shadow.tooltip': `Display a shadow effect on the cards`,

	'widget_editor.animation.label.border': 'Story border animation',
	'widget_editor.animation.name.none': `None`,
	'widget_editor.animation.name.chain_pulse': `Chain Pulse`,
	'widget_editor.animation.name.continuous_pulse': `Continuous Pulse`,

	'widget_editor.placement.options.inline': 'Inline',
	'widget_editor.placement.options.custom': 'Custom',
	'widget_editor.placement.custom_target.label': `Your div's ID`,

	'widget_editor.placement.position.before': 'Before this location',
	'widget_editor.placement.position.inside': 'Inside the top of this location',
	'widget_editor.placement.position.bottom': 'At the bottom of this location',
	'widget_editor.placement.position.after': 'After this location',
	'widget_editor.placement.position.replace': 'In the location (replace it)',

	'widget_editor.stories.button.change_design': 'Change design',
	'widget_editor.stories.button.add': 'Add stories',
	'widget_editor.stories.alert.story_disabled': 'The story is disabled and will not be displayed in the banner',

	'widget_editor.whitelist.title': `Where should it appear ?`,
	'widget_editor.whitelist.radio.all_pages': 'All pages',
	'widget_editor.whitelist.radio.some_pages': 'Some pages',
	'widget_editor.blacklist.button.add': 'Add exceptions',
	'widget_editor.blacklist.button.remove': 'Erase all exceptions',

	'widget_editor.loader_image.button': 'Setup Loader',
	'widget_editor.loader_image.description': `When the widget is opened, this image is displayed while the story is loading.`,

	'widget_editor.floating_widget.active_button': 'Activate Floating Widget',
	'widget_editor.floating_widget.active_header': 'Embed the story on your website with the floating widget',
}
