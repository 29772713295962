import { css } from '@emotion/react'
import { PREVIEW_HEIGHT } from 'lib/constants'
import { snackeet_blue } from 'styles/colors'

export const globalStyles = css`
	#__next {
		height: 100vh;
		width: 100%;
	}

	// Normalize p, h1, h2, h3
	p,
	h1,
	h2,
	h3 {
		margin: 0;
		padding: 0;
	}

	h1,
	h2,
	h3 {
		font-size: 100%;
		font-weight: normal;
	}

	::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}

	::-webkit-scrollbar-track {
		background: #e4e3e3;
		border-radius: 10px;
	}

	::-webkit-scrollbar-thumb {
		background: #2f316187;
		border-radius: 6.5px;
	}

	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type='number'] {
		-moz-appearance: textfield;
	}

	input,
	textarea,
	pre {
		font-family: 'Poppins', sans-serif;
	}

	iframe {
		border: none;
	}

	// Override react-moveable CSS
	.moveable-control {
		height: 10px !important;
		width: 10px !important;
		margin-top: -5px !important;
		margin-left: -5px !important;
		border: none !important;
		box-shadow:
			0px 3px 1px -2px rgba(0, 0, 0, 0.2),
			0px 2px 2px 0px rgba(0, 0, 0, 0.14),
			0px 1px 5px 0px rgba(0, 0, 0, 0.12);
		background-color: rgba(255, 255, 255, 0.9) !important;
		border-radius: 0 !important;
	}

	.moveable-nw,
	.moveable-ne,
	.moveable-sw,
	.moveable-se {
		border-radius: 50% !important;
	}

	.moveable-control.moveable-n,
	.moveable-control.moveable-s {
		height: 6px !important;
		width: 12px !important;
		margin-top: -3px !important;

		border-radius: 8px !important;
	}

	.moveable-control.moveable-e,
	.moveable-control.moveable-w {
		height: 12px !important;
		width: 6px !important;
		margin-left: -3px !important;

		border-radius: 8px !important;
	}

	.moveable-bold.moveable-vertical {
		width: 1px !important;
	}
	.moveable-bold.moveable-horizontal {
		height: 1px !important;
	}

	.moveable-normal.moveable-vertical {
		width: 1px !important;
	}
	.moveable-normal.moveable-horizontal {
		height: 1px !important;
	}

	.moveable-origin {
		border-color: #f55 !important;
		background: #fff !important;
		width: 12px !important;
		height: 12px !important;
		margin-top: -6px !important;
		margin-left: -6px !important;
		border-radius: 50% !important;
	}

	.moveable-rotation-control {
		border-width: 1px !important;
		border-radius: 50% !important;
		background-image: url('/static/images/rotate-icon.png') !important;
		background-size: contain !important;
		height: 20px !important;
		width: 20px !important;
		transform: translate(-4px, -15px) scale(1) !important;
	}

	.moveable-rotation-line {
		display: none !important;
	}

	.moveable-vertical {
		max-height: ${PREVIEW_HEIGHT}px !important;
	}

	.notistack-SnackbarContainer {
		z-index: 3300 !important;

		.origin-top {
			top: 40px;
		}
	}

	.moveable-control-box {
		--moveable-color: ${snackeet_blue} !important;
	}

	:root {
		--correct-color: #5bba74;
		--correct-color-shaded: #00600f;
		--incorrect-color: #f34e4e;
		--incorrect-color-shaded: #b71c1c;
		--moveable-color: ${snackeet_blue} !important;
	}
`
