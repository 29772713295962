// Based on example: https://github.com/mui/material-ui/blob/master/examples/material-ui-nextjs-ts-v4-v5-migration/pages/_app.tsx

import 'styles/fonts.css';
import 'draft-js/dist/Draft.css';
import 'styles/videoTimelineCursor.scss';
import 'styles/GifPickerReact.css';
import { CacheProvider, EmotionCache, Global } from '@emotion/react';
import { shouldPolyfill as shouldPolyfillDatetimeFormat } from '@formatjs/intl-datetimeformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillIntlLocale } from '@formatjs/intl-locale/should-polyfill';
import CssBaseline from '@mui/material/CssBaseline';
import { enUS, frFR } from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { UploadErrorReport } from 'atoms/SnackbarVariants';
import withAuth from 'containers/withAuth';
// Enable immer Patches
import { enablePatches } from 'immer';
import { formattedMessageDefaultValues } from 'lib/constants';
import useCustomFonts from 'lib/hooks/useCustomFonts';
import useThirdPartyIdentification from 'lib/hooks/useThirdPartyIdentification';
import { getTranslations } from 'lib/translations';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { SnackbarProvider, useSnackbar } from 'notistack';
import type { ReactNode } from 'react';
import React, { useEffect } from 'react';
import { IntlProvider, useIntl } from 'react-intl';
import { useSelector, useStore } from 'react-redux';
import { getAppLocale } from 'redux/customer/selectors';
import { getFonts } from 'redux/fonts/selectors';
import { getTimezone } from 'redux/organization/selectors';
import { PersistGate } from 'redux-persist/integration/react';
import { globalStyles } from 'styles/GlobalStyles';
import { createEmotionCache, snackeetTheme } from 'styles/styles';
import { wrapper } from '../src/store';
enablePatches();

// Dynamic import + capability detection
// https://formatjs.io/docs/polyfills/intl-datetimeformat/#dynamic-import--capability-detection
async function polyfill(locale: string) {
  // This platform already supports Intl.Locale
  if (shouldPolyfillIntlLocale()) {
    await import('@formatjs/intl-locale/polyfill');
  }
  const unsupportedLocale = shouldPolyfillDatetimeFormat(locale);
  // This locale is supported
  if (!unsupportedLocale) {
    return;
  }
  // Load the polyfill 1st BEFORE loading data
  await import('@formatjs/intl-datetimeformat/polyfill-force');

  // Parallelize CLDR data loading
  const dataPolyfills = [import('@formatjs/intl-datetimeformat/add-all-tz'), import(`@formatjs/intl-datetimeformat/locale-data/${unsupportedLocale}`)];
  await Promise.all(dataPolyfills);
}
const intlMessages: any = getTranslations();
const locales = {
  frFR,
  enUS
};

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();
export interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
  err?: any;
}
declare module 'notistack' {
  interface VariantOverrides {
    uploadErrorReport: true;
  }
}
function App(props: MyAppProps) {
  const {
    Component,
    emotionCache = clientSideEmotionCache,
    pageProps,
    err
  } = props;
  const store = useStore();
  const appLocale = useSelector(getAppLocale);
  const appTimezone = useSelector(getTimezone);
  const fonts = useSelector(getFonts);
  const muiLocale = convertLocaleTolocaleImportName(appLocale);
  const themeWithLocale = createTheme(snackeetTheme, locales[muiLocale]);
  polyfill(appLocale);
  useThirdPartyIdentification();
  useCustomFonts(fonts);
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles?.parentElement?.removeChild(jssStyles);
    }
  }, []);
  return <PersistGate persistor={(store as any).__persistor} loading={<div>Loading</div>}>
			<CacheProvider value={emotionCache}>
				<ThemeProvider theme={themeWithLocale}>
					<SnackbarProvider autoHideDuration={3000} anchorOrigin={{
          horizontal: 'right',
          vertical: 'top'
        }} classes={{
          anchorOriginTopRight: 'origin-top',
          anchorOriginTopCenter: 'origin-top',
          anchorOriginTopLeft: 'origin-top'
        }} Components={{
          uploadErrorReport: UploadErrorReport
        }}>
						<CssBaseline />
						<Global styles={globalStyles} />
						<Head>
							<title>Snackeet</title>
							<meta name='viewport' content='user-scalable=0, initial-scale=1, minimum-scale=1, width=device-width, height=device-height' />
						</Head>
						<IntlProvider locale={appLocale} timeZone={appTimezone} messages={intlMessages[appLocale]} defaultRichTextElements={formattedMessageDefaultValues}>
							<ReactQueryProvider>
								{/* // Workaround for https://github.com/vercel/next.js/issues/8592 */}
								<Component {...pageProps} err={err} />
							</ReactQueryProvider>
						</IntlProvider>
					</SnackbarProvider>
				</ThemeProvider>
			</CacheProvider>
		</PersistGate>;
}
function ReactQueryProvider({
  children
}: {
  children: ReactNode;
}) {
  const intl = useIntl();
  const {
    enqueueSnackbar
  } = useSnackbar();
  const [queryClient] = React.useState(() => new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 30_000
      }
    },
    queryCache: new QueryCache({
      // Handle errors as described here: https://tkdodo.eu/blog/breaking-react-querys-api-on-purpose
      onError: (_error, query) => {
        if (query.meta?.errorMessageId) {
          enqueueSnackbar(intl.formatMessage({
            id: (query.meta?.errorMessageId as string)
          }), {
            variant: 'error'
          });
        }
      }
    })
  }));
  return <QueryClientProvider client={queryClient}>
			{/* // Workaround for https://github.com/vercel/next.js/issues/8592 */}
			{children}
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>;
}
export default wrapper.withRedux(withAuth(App));

// Supported locales: https://mui.com/material-ui/guides/localization/#supported-locales
function convertLocaleTolocaleImportName(appLocale: string) {
  switch (appLocale) {
    case 'fr':
      return 'frFR';
    default:
      return 'enUS';
  }
}